import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate} from "react-router";
import styled from "styled-components";
import {Spacing} from "../MainPage";
import Dropdown from "../../component/Dropdown";
import PostItem from "../../component/PostItem";
import {useRecoilState} from "recoil";
import {menuState} from "../../../recoil/atom";
import {magazineApi} from "../../../api/magazine/magazineApi";

const DROPDOWN_ITEM = [{label: 'Newest', key: 'newest'}, {label: 'Oldest', key: 'oldest'}];
const MagazineBasePage = () => {
    const [selectedMenu, setSelectedMenu] = useRecoilState(menuState);
    const navigate = useNavigate();

    const [selectedItem, setSelectedItem] = useState(DROPDOWN_ITEM[0]);
    const handlePostClick = (id) => {
        navigate(`/magazine/${id}`);

    }
    const [posts, setPosts] = useState();

    useEffect(() => {
        (async () => {
            const {data} = await magazineApi.getPosts();
            setPosts(data);
        })();
    }, []);

    const sortedPosts = useMemo((key) => {
        if (selectedItem.key === 'newest') {
            return posts?.sort((a, b) => new Date(b.createDate) - new Date(a.createDate))
        }
        return posts?.sort((a, b) => new Date(a.createDate) - new Date(b.createDate))
    }, [posts, selectedItem.key]);

    const handleClickDropdown = (item) => {
        setSelectedItem(item);
    }

    const handleMenuClick = (menuKey) => {
        setSelectedMenu(menuKey);
        navigate(`/${menuKey}`);
    }

    return (
        <Container>
            <SubMenuContainer>
                <MenuWrapper>
                    <Menu size="18px" selected={selectedMenu?.includes('fashion')}
                          onClick={() => handleMenuClick("magazine/fashion")}>
                        Fashion
                    </Menu>
                    <Menu size="18px" selected={selectedMenu?.includes('art')}
                          onClick={() => handleMenuClick("magazine/art")}>
                        Art
                    </Menu>
                </MenuWrapper>
            </SubMenuContainer>
            <Spacing top="40px"/>
            <HeaderWrapper>
                <PageTitle>MAGAZINE</PageTitle>
                <Spacing top="8px"/>
                <Dropdown selectedItem={selectedItem} items={DROPDOWN_ITEM} onClick={handleClickDropdown}/>
            </HeaderWrapper>
            <MagazineListWrapper>
                {sortedPosts?.map((post) => <PostItem
                    key={post.id}
                    content={post}
                    width={`calc((100vw - 45px) / 2)`}
                    img={post.basicThumbnail}
                    title={post.title}
                    onClick={handlePostClick}/>)}
            </MagazineListWrapper>
        </Container>
    )
};

export default MagazineBasePage;

export const Container = styled.div`
  font-family:"Helvetica LT W05 Roman";
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 0 20px;
  box-sizing: border-box;
`;

export const SubMenuContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 30px;
  background-color: #ffffff;
`;

export const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Menu = styled.div`
  width: ${({width}) => width ? width : '80px'};
  min-width: ${({width}) => width ? width : '80px'};
  padding: 10px 10px 10px 0;
  font-size: 20px;
  font-weight: 700;

  &:hover {
    cursor: pointer;
  }

  ${({selected}) => selected ? 'color: #C02126' : 'color:#000000'};
`;

export const PageTitle = styled.div`
  color: #000;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.08px;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
  padding-right: 20px;
`;

const MagazineListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 50px 5px;
  align-items: center;
`;
