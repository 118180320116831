import React from 'react';
import styled from "styled-components";

const MainGoodItem = ({color, width, thumbnailWidth, height, name, price, img, onClick}) => {
    return (
        <MainGoodItemWrapper color={color} width={width} height={height} src={img}>
            <GoodContent thumbnailWidth={thumbnailWidth}>
                <Content>{name}</Content>
                {price && <Content>${price}</Content>}
                <BuyNowButton onClick={onClick}>Learn more</BuyNowButton>
            </GoodContent>
        </MainGoodItemWrapper>
    );
};

export default MainGoodItem;

MainGoodItem.defaultProps = {
    type: 'right',
    color: '#fff',
    width: "100%",
    thumbnailWidth: "40%",
    height: "160px",
}

const MainGoodItemWrapper = styled.div`
  width: ${({width}) => width && width};
  height: ${({height}) => height && height};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 10px;
  box-sizing: border-box;
  
  ${({color}) => color && `background-color: ${color}`};
  background-image: url(${({src}) => src && src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
`;

const GoodContent = styled.div`
  //width: ${({thumbnailWidth}) => thumbnailWidth && `calc(100% - ${thumbnailWidth})`};
  height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 15px;
  box-sizing: border-box;
  
`;

const Content = styled.div`
  width: 100%;
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  font-family:"Helvetica LT W05 Bold";
`;

const BuyNowButton = styled.div`
  width: 150px;
  height: 25px;
  white-space: nowrap;
  background: #000;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-family:"Helvetica LT W05 Bold";
  font-weight: 700;
  box-sizing: border-box;
  padding: 0 5px;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;
