import React from 'react';
import styled from "styled-components";

const Banner = ({images, speed}) => {
    return (
        <Container>
            <BannerWrapper>
                <BannerSection style={{ "--speed": `${speed}ms` }}>
                    {images.map(({ id, image }) => (
                        <div className="image" key={id}>
                            <img src={image} alt={id} />
                        </div>
                    ))}
                </BannerSection>
                <BannerSection style={{ "--speed": `${speed}ms` }}>
                    {images.map(({ id, image }) => (
                        <div className="image" key={id}>
                            <img src={image} alt={id} />
                        </div>
                    ))}
                </BannerSection>
                <BannerSection style={{ "--speed": `${speed}ms` }}>
                    {images.map(({ id, image }) => (
                        <div className="image" key={id}>
                            <img src={image} alt={id} />
                        </div>
                    ))}
                </BannerSection>
            </BannerWrapper>
        </Container>
    );
};

export default Banner;

const Container = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 35px;

  img {
    height: 100%;
    object-fit: cover;
  }

  img:last-of-type {
    padding-left: 0;
  }

  @keyframes swipe {
    0% {
      transform: translate(0);
    }

    100% {
      transform: translate(-100%);
    }
  }
`;
const BannerWrapper = styled.div`
  position: absolute;
  display: flex;
`;

const BannerSection = styled.div`
  display: flex;
  animation: swipe var(--speed) linear infinite backwards;
`;
