import {getRequest} from "../../axios";

const baseUrl = `/api/v1/magazine`;

const getPosts = () =>
    getRequest({
        url: `${baseUrl}`,
    });

const getPostById = (postId) =>
    getRequest({
        url: `${baseUrl}/${postId}`,
    });

const getPostByCategory = (category) =>
    getRequest({
        url: `${baseUrl}/category/${category}`,
    });

export const magazineApi = {
    getPosts,
    getPostById,
    getPostByCategory
}



