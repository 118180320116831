import React, {useMemo} from 'react';
import styled from "styled-components";
import {Spacing} from "../pages/MainPage";
import PostItem from "./PostItem";


const GoodItem = ({good, img, name, subName, price, width, height, onClick, bySearch, searchKey}) => {
    const handleClickPost = () => {
        onClick && onClick(good.id);
    }

    const coloredName = useMemo(() => {
        if (bySearch && searchKey !== '') {
            let splitTitle = name.split(' ');
            splitTitle = splitTitle.map((key) => {
                if (key.toLowerCase().includes(searchKey.toLowerCase())) {
                    return `<span style="background-color: red; color: black;">${key}</span>`;
                }
                return `<span style="color: #000;">${key}</span>`;
            })
            return splitTitle.join(' ');
        }
    }, [bySearch, searchKey, name]);

    return (
        <Container width={width} height={height}>
            <GoodThumbnail src={img} width={width} height={height} onClick={handleClickPost}/>
            <Spacing top="20px"/>
            <GoodInfoWrapper>
                {bySearch && searchKey !== '' ?
                    <Name onClick={handleClickPost} dangerouslySetInnerHTML={{__html: coloredName}}/> :
                    <Name onClick={handleClickPost}>{name}</Name>}
                {good?.description && good?.description?.split(" ")?.length > 2 &&
                <SubName
                    onClick={handleClickPost}>{good?.description.split(" ")?.[0] + " " + good?.description.split(" ")?.[1] + " " + good?.description.split(" ")?.[2]}</SubName>}

                {/*<Price onClick={handleClickPost}>{`$${price || 0}`}</Price>*/}
            </GoodInfoWrapper>
        </Container>
    );
};

export default GoodItem;

GoodItem.defaultProps = {
    width: "160px",
    height: "238px",
}

const GoodThumbnail = styled.img`
  width: ${({width}) => width && width};
  height: ${({height}) => height && height};
  object-fit: cover;
`;

const Container = styled.div`
  width: ${({width}) => width && width};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  &:hover {
    cursor: pointer;
  }
`;

const GoodInfoWrapper = styled.div`
  width: 100%;
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
`;

const Name = styled.div`
  font-size: 17px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

const SubName = styled.div`
  font-size: 13px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

const Price = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 700;
`;
