import React, {useState, useMemo, useEffect, useContext} from 'react';
import SearchIconHoverSrc from "../../asset/icon__search-hover.png";
import SearchIconSrc from "../../asset/icon__search.png";
import styled from "styled-components";
import CloseIconSrc from "../../asset/icon__close.png";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import RelatedPostList from "./RelatedPostList";
import RelatedGoodList from "./RelatedGoodList";
import {Spacing} from "../pages/MainPage";
import {ShopContext} from "../../context/ShopProvider";
import {magazineApi} from "../../api/magazine/magazineApi";

export const SearchTab = ({open, onClose}) => {

    const {fetchAllProducts, products} = useContext(ShopContext);

    useEffect(()=>{
        fetchAllProducts();
    },[]);
    const [posts, setPosts] = useState();

    useEffect(() => {
        (async () => {
            const {data} = await magazineApi.getPosts();
            setPosts(data);
        })();
    }, []);

    const [value, setValue] = useState('');
    const [showResult, setShowResult] = useState(false);
    const relatedMagazineIdList = useMemo(() => posts?.filter(({title}) => title.toLowerCase().includes(value.toLowerCase()))?.map(({id}) => id.toString()), [value]);
    const relatedShopIdList = useMemo(() => products?.filter(({title}) => title.toLowerCase().includes(value.toLowerCase()))?.map(({handle}) => handle), [value]);

    const handleClose = () => {
        setValue('');
        setShowResult(false);
        onClose && onClose();
    }
    const handleClear = () => {
        setValue('');
        setShowResult(false);
    };
    const handleSearch = () => {
        setShowResult(true);
    }
    const handleOnKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    }

    if (!products) return <div>Loadings..</div>
    return <>
        {open &&
        <Background onClick={handleClose}>
            <SearchTabWrapper onClick={(e) => e.stopPropagation()}>
                <SearchInputWrapper onKeyPress={handleOnKeyPress}>
                    <SearchInput value={value} onChange={(e) => setValue(e.target.value)}
                                 placeholder="Search magazine and shop"/>
                    {value && <ClearButton src={CloseIconSrc} width="20px" onClick={handleClear}/>}
                    {<SearchButton src={SearchIconSrc} width="20px" onClick={handleSearch}/>}
                </SearchInputWrapper>
            </SearchTabWrapper>
            {showResult &&
            <SearchResult
                onClick={handleClose}
                searchKey={value}
                relatedMagazineIdList={relatedMagazineIdList}
                relatedShopIdList={relatedShopIdList}
            />}
        </Background>
        }
    </>
};

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
`;

const SearchTabWrapper = styled.div`
  position: absolute;
  top: 50px;
  left: calc(50vw - 250px);
  background-color: white;
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  z-index: 200;
`;

const SearchInputWrapper = styled.div`
  display: inline-block;
`;

const SearchInput = styled.input`
  width: 550px;
  height: 55px;
  background: #ffffff;
  font-size: 25px;
  font-family: inherit;
  padding: 0 46px 0 16px;
  outline: none;
  box-sizing: border-box;
  position: relative;
  border: none;
`;

const ClearButton = styled.img`
  width: 20px;
  position: absolute;
  top: 17px;
  right: 50px;
  outline: none;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
`;

const SearchButton = styled.img`
  width: 25px;
  position: absolute;
  top: 15px;
  right: 16px;
  outline: none;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
`;

const SearchResult = ({relatedMagazineIdList, relatedShopIdList, searchKey, onClick}) => {

    useEffect(() => {
        const setScreenSize = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        setScreenSize();
        window.addEventListener('resize', setScreenSize);

        return () => window.removeEventListener('resize', setScreenSize);
    }, []);

    const [tabIndex, setTabIndex] = useState(0);

    return <SearchResultWrapper onClick={(e) => e.stopPropagation()}>
        <Tabs selectedIndex={tabIndex} onSelect={setTabIndex}>
            <TabList>
                <Tab>All</Tab>
                <Tab>Magazine</Tab>
                <Tab>Shop</Tab>
            </TabList>
            <TabPanel>
                <TabPanelContentWrapper>
                    <Spacing top="30px"/>
                    <Title>Magazine</Title>
                    <Spacing top="20px"/>
                    {relatedMagazineIdList?.length > 0 ?
                        <>
                            <RelatedPostList onClick={onClick} bySearch searchKey={searchKey}
                                             relatedPostIdList={relatedMagazineIdList.slice(0, 6)}/>
                            {relatedMagazineIdList?.length > 6 &&
                            <>
                                <ShowMoreButton onClick={() => setTabIndex(1)}>Show More</ShowMoreButton>
                                <Spacing top="20px"/>
                            </>}
                        </>
                        : <NoResult/>
                    }
                    <Divider/>
                    <Spacing top="20px"/>
                    <Title>Shop</Title>
                    <Spacing top="20px"/>
                    {relatedShopIdList?.length > 0 ?
                        <>
                            <RelatedGoodList onClick={onClick} bySearch searchKey={searchKey}
                                             relatedGoodIdList={relatedShopIdList.slice(0, 6)}/>
                            {relatedShopIdList?.length > 6 &&
                            <>
                                <Spacing top="20px"/>
                                <ShowMoreButton onClick={() => setTabIndex(2)}>Show More</ShowMoreButton>
                            </>
                            }
                        </>
                        : <NoResult/>
                    }
                    <Spacing top="20px"/>
                </TabPanelContentWrapper>
            </TabPanel>
            <TabPanel>
                <TabPanelContentWrapper>
                    <Spacing top="30px"/>
                    <RelatedPostList onClick={onClick} bySearch searchKey={searchKey}
                                     relatedPostIdList={relatedMagazineIdList}/>
                    <Spacing top="20px"/>
                </TabPanelContentWrapper>
            </TabPanel>
            <TabPanel>
                <TabPanelContentWrapper>
                    <RelatedGoodList onClick={onClick} bySearch searchKey={searchKey}
                                     relatedGoodIdList={relatedShopIdList}/>
                    <Spacing top="20px"/>
                </TabPanelContentWrapper>
            </TabPanel>
        </Tabs>
    </SearchResultWrapper>;
}

export const NoResult = () => {
    return <TabPanelContentWrapper>
        <Spacing top="10px"/>
        No result.
        <Spacing top="30px"/>
    </TabPanelContentWrapper>
}

const ShowMoreButton = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }
`;

const SearchResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 105px;
  left: calc(50vw - 250px);
  background-color: white;
  width: 550px;
  height: calc(100vh - 155px);

  .react-tabs__tab-list {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    height: 50px;

    .react-tabs__tab {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      font-size: 24px;
      width: calc(100% / 3);
      height: 100%;
      border-radius: 0;

      &--selected {
        background-color: #ececec;
        border: none;
      }
    }

  }

  .react-tabs__tab-panel {
    overflow-y: auto;
    max-height: calc(100vh - 220px);
    height: calc(100vh - 220px);
  }

  .react-tabs__tab-panel--selected {

  }
`;

const TabPanelContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: fit-content;
  padding: 0 20px;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-size: 24px;
`;

const Divider = styled.div`
  width: 510px;
  border: 0.5px solid #000;
  box-sizing: border-box;
`;

const Search = ({onClick}) => {
    const [searchHover, setSearchHover] = useState(false);

    return (
        <SearchIconWrapper
            onMouseOver={() => setSearchHover(true)}
            onMouseOut={() => setSearchHover(false)}>
            <SearchIcon onClick={onClick} src={searchHover ? SearchIconHoverSrc : SearchIconSrc}/>
        </SearchIconWrapper>
    );
};

export default Search;

const SearchIconWrapper = styled.div``;

const SearchIcon = styled.img`
  src: ${({src}) => src && src};
  width: 30px;

  &:hover {
    cursor: pointer;
  }
`;
