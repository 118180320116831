import React from 'react';
import styled from "styled-components";

const MainCategoryPostItem = ({
                              type,
                              title,
                              titleStyle,
                              showSubTitle,
                              subTitle,
                              subTitleStyle,
                              width,
                              height,
                              thumbnailWidth,
                              thumbnailSrc,
                              color,
                              onClick
                          }) => {
    return (
        <CategoryPostWrapper width={width} height={height} onClick={onClick} src={thumbnailSrc}>
            {(type === 'left' || type === 'center') &&
            <img src={thumbnailSrc} style={{objectFit: 'cover', width: thumbnailWidth, height: height}}/>}
            {type !== 'center' && <CategoryPostContent color={color} >
                <Title {...titleStyle}>{title}</Title>
                {showSubTitle && <SubTitle {...subTitleStyle} style={{...subTitleStyle}}>{subTitle}</SubTitle>}
            </CategoryPostContent>}
            {type === 'right' &&
            <img src={thumbnailSrc} style={{objectFit: 'cover', width: thumbnailWidth, height: height}}/>}
        </CategoryPostWrapper>
    );
};

export default MainCategoryPostItem;

MainCategoryPostItem.defaultProps = {
    type: 'right',
    showSubTitle: true,
    width: "100%",
    height: "564px",
    thumbnailWidth: "50%",
    titleStyle: {
        color: "#000000",
        size: "55px",
        weight: 700,
    },
    subTitleStyle: {
        color: "#000000",
        size: "20px",
        weight: 500,
    },
}

const CategoryPostWrapper = styled.div`
  width: ${({width}) => width && width};
  height: ${({height}) => height && height};
  ${({src}) => src && `background-image: url(${src})`};
  //background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  
  display: flex;
  flex-direction: row;

  &:hover {
    cursor: pointer;
  }
`;


const Title = styled.div`
  width: 100%;
  color: ${({color}) => color && color};
  font-size: ${({size}) => size && size};
  font-style: normal;
  font-weight: ${({weight}) => weight && weight};
  line-height: normal;
  white-space: pre-line;
`;

const CategoryPostContent = styled.div`
  width: ${({thumbnailWidth}) => thumbnailWidth && `calc(100% - ${thumbnailWidth})`};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 10px;
  box-sizing: border-box;
  ${({color}) => color && `background-color: ${color}`};
`;

const SubTitle = styled.div`
  width: 100%;
  color: ${({color}) => color && color};
  font-size: ${({size}) => size && size};
  font-style: normal;
  font-weight: ${({weight}) => weight && weight};
  line-height: normal;
`;
