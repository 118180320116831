import React, {useEffect, useState} from 'react';
import PostItem from "./PostItem";
import styled from "styled-components";
import {useNavigate} from "react-router";
import {Spacing} from "../pages/MainPage";
import {NoResult} from "./Search";
import {magazineApi} from "../../api/magazine/magazineApi";

const RelatedPostList = ({relatedPostIdList, bySearch= false, searchKey = '', onClick}) => {
    const navigate = useNavigate();

    const handlePostClick = (id, category) => {
        navigate(`/magazine/${id}`);
    }
    const [posts, setPosts] = useState();

    useEffect(() => {
        (async () => {
            const {data} = await magazineApi.getPosts();
            setPosts(data);
        })();
    }, []);

    const relatedPosts = posts?.filter(({id}) => relatedPostIdList?.includes(id.toString())).slice(0, 8);

    return (
        <Container>
            {!bySearch && <Title>Discover More</Title>}
            {!bySearch && <Spacing top="15px"/>}
            <MagazineListWrapper>
                {relatedPosts?.map((post) => <PostItem
                    bySearch
                    searchKey={searchKey}
                    key={post.id}
                    content={post}
                    width={`${bySearch ? 'calc((550px - 80px) / 2)' : 'calc((100vw - 80px) / 8)'}`}
                    height={`${bySearch ? 'calc((550px - 80px) / 2 + 100px)' : 'calc((100vw - 80px) / 8 + 150px)'}`}
                    img={post.basicThumbnail}
                    title={post.title}
                    onClick={(id, category) => {
                        onClick && onClick();
                        handlePostClick(id, category);
                    }}/>)
                }
                {bySearch && relatedPostIdList?.length === 0 && <NoResult/>}
            </MagazineListWrapper>
        </Container>
    );
};

export default RelatedPostList;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  //padding: 0 20px 20px 20px;
`;
const MagazineListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  //gap: 10px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-size: 18px;
`;
