import React, {useRef, Suspense, useState, useEffect} from 'react';
import useRouterScrollTop from "../../../hooks/useRouterScrollTop";
import styled from "styled-components";

const Contents = (props) => {
    useEffect(() => {
        const setScreenSize = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        setScreenSize();
        window.addEventListener('resize', setScreenSize);

        return () => window.removeEventListener('resize', setScreenSize);
    }, []);
    const {children} = props;
    const scrollRef = useRef(null);

    useRouterScrollTop(scrollRef.current);

    return (
        <ScrollBoundary id="offshoot-contents" ref={scrollRef}>
            <Suspense>{children}</Suspense>
        </ScrollBoundary>
    );
};

export default Contents;

const ScrollBoundary = styled.div`
  display: flex;
  //height: calc(100vh - 50px);
  height: calc(calc(var(--vh, 1vh) * 100) - 50px);
  align-items: flex-start;
  overflow: auto;
  
  
  @media only screen and (min-width: 100vw) {
    overflow-x: hidden;
  } 
`
