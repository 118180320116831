import React, {useContext, useState} from 'react';
import styled from "styled-components";
import LogoHoverImgSrc from "../../../asset/img__logo-open.png";
import LogoImgSrc from "../../../asset/img__logo-close.png";

import Drawer from 'react-modern-drawer';
import Basket, {BasketContent} from "../../component/Basket";
import 'react-modern-drawer/dist/index.css'
import Search, {SearchTab} from "../../component/Search";
import {useRecoilState} from "recoil";
import {basketState, menuState, subMenuState} from "../../../recoil/atom";
import {useNavigate} from "react-router";

import {ShopContext} from "../../../context/ShopProvider";

export const GNB_TYPE = {
    DEFAULT: 'default',
    GAUGE: 'gauge',
}

const Gnb = ({type}) => {

    const {drawerOpen, openDrawer, closeDrawer, checkout, removeLineItem} = useContext(ShopContext);

    const navigate = useNavigate();
    const [selectedMenu, setSelectedMenu] = useRecoilState(menuState);
    const [selectedSubMenu, setSelectedSubMenu] = useRecoilState(subMenuState);
    const [logoHover, setLogoHover] = useState(false);

    // const [drawerOpen, setDrawerOpen] = useState(false);
    const [searchTabOpen, setSearchTabOpen] = useState(false);

    const [basketItem] = useRecoilState(basketState);

    const handleDrawerClose = () => {
        closeDrawer((prev) => !prev);
    }

    return (
        <Container>
            <MenuContainer type={type}>
                <MenuWrapper>
                    <Menu size="18px" selected={selectedMenu?.includes('magazine')}
                          onClick={() => {
                              setSelectedSubMenu('all');
                              navigate("/magazine");
                          }}>
                        Magazine
                    </Menu>
                    <Menu size="18px" selected={selectedMenu?.includes('shop')}
                          onClick={() => {
                              setSelectedSubMenu('all');
                              navigate("/shop/3d");
                          }}>
                          {/*onClick={() => window.location.replace("https://offshootdot.myshopify.com")}>*/}
                        Shop
                    </Menu>
                    <Menu size="18px" selected={selectedMenu?.includes('pistol')}
                          onClick={() => {
                              setSelectedSubMenu('all');
                              navigate("/pistol");
                          }}>
                        Pistol
                    </Menu>
                </MenuWrapper>
                <LogoWrapper
                    onClick={() =>
                        navigate("/")
                    }
                    onMouseOver={() => setLogoHover(true)}
                    onMouseOut={() => setLogoHover(false)}>
                    <img src={logoHover ? LogoHoverImgSrc : LogoImgSrc}/>
                </LogoWrapper>
                <UtilityWrapper>
                    <Search onClick={() => setSearchTabOpen(true)}/>
                    <Basket itemCount={checkout?.lineItems?.length} onClick={() => {
                        openDrawer();
                    }}/>
                </UtilityWrapper>
            </MenuContainer>
            {selectedMenu?.includes('magazine') && type !== GNB_TYPE.GAUGE && <SubMenuContainer>
                <MenuWrapper>
                    <Menu size="18px" selected={selectedMenu?.includes('fashion')}
                          onClick={() => navigate("magazine/fashion")}>
                        Fashion
                    </Menu>
                    <Menu size="18px" selected={selectedMenu?.includes('art')}
                          onClick={() => navigate("magazine/art")}>
                        Art
                    </Menu>
                </MenuWrapper>
            </SubMenuContainer>}
            {selectedMenu?.includes('shop') && type !== GNB_TYPE.GAUGE && <SubMenuContainer>
                <MenuWrapper>
                    <Menu size="18px" selected={selectedMenu?.includes('2d')}
                          onClick={() => {
                              setSelectedSubMenu('all');
                              navigate("shop/2d");
                          }}>
                        2D
                    </Menu>
                    <Menu size="18px" selected={selectedMenu?.includes('3d')}
                          onClick={() => {
                              setSelectedSubMenu('all');
                              navigate("shop/3d");
                          }}>
                        3D
                    </Menu>
                    <Menu size="18px" selected={selectedMenu?.includes('4d')}
                          onClick={() => {
                              setSelectedSubMenu('all');
                              navigate("shop/4d");
                          }}>
                        4D
                    </Menu>
                    <Menu size="18px" selected={selectedMenu?.includes('xd')}
                          onClick={() => {
                              setSelectedSubMenu('all');
                              navigate("shop/xd");
                          }}>
                        XD
                    </Menu>
                </MenuWrapper>
            </SubMenuContainer>}
            <Drawer
                size={450}
                open={drawerOpen}
                onClose={handleDrawerClose}
                direction='right'
                className='drawer__basket'
            >
                <BasketContent onClose={handleDrawerClose}/>
            </Drawer>
            <SearchTab open={searchTabOpen} onClose={() => setSearchTabOpen(false)}/>
        </Container>
    )
};

export default Gnb;

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;
const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  //max-width: calc(max(100vw, 1630px));
  width: 100vw;
  min-width: calc(max(750px, 100vw));
  height: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  
  padding: 0 20px;

  z-index: 5;
  position: fixed;
  ${({type}) => type === GNB_TYPE.DEFAULT ? 'background-color:#ffffff' : 'background-color:none'};
`;
const SubMenuContainer = styled.div`
  display: flex;
  align-items: center;
  //width: calc(max(100vw, 1630px));
  width: 100vw;
  min-width: calc(max(750px, 100vw));
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  min-width: 1160px;
  height: 30px;
  padding: 0 20px;
  z-index: 5;
  position: fixed;
  top: 50px;
  background-color: #ffffff;
`;

const MenuWrapper = styled.div`
  width: 120px;
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  text-align: center;
  align-items: center;
`;

const Menu = styled.div`
  width: 120px;
  padding: 10px;
  font-size: ${({size}) => size && size};
  font-weight: 700;

  &:hover {
    cursor: pointer;
  }

  ${({selected}) => selected ? 'color: #C02126' : 'color:#000000'};
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 250px;
  height: 30px;
  &:hover {
    cursor: pointer;
  }
`;

const UtilityWrapper = styled.div`
  width: 120px;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  align-items: center;
`;
