import React from 'react';
import styled from "styled-components";

const CategoryPostItem = ({
                              type,
                              title,
                              titleStyle,
                              showSubTitle,
                              subTitle,
                              subTitleStyle,
                              width,
                              height,
                              thumbnailWidth,
                              thumbnailSrc,
                              color,
                              onClick
                          }) => {
    return (
        <CategoryPostWrapper width={width} height={height} onClick={onClick}>
            {(type === 'left' || type === 'center') &&
            <Thumbnail src={thumbnailSrc} width={thumbnailWidth} height={height}/>}
            {type !== 'center' && <CategoryPostContent thumbnailWidth={thumbnailWidth} width={width} height={height} color={color}>
                <Title {...titleStyle}>{title}</Title>
                {showSubTitle && <SubTitle {...subTitleStyle}>{subTitle}</SubTitle>}
            </CategoryPostContent>}
            {type === 'right' &&
            <Thumbnail src={thumbnailSrc} width={thumbnailWidth} height={height}/>}
        </CategoryPostWrapper>
    );
};

export default CategoryPostItem;

CategoryPostItem.defaultProps = {
    type: 'right',
    showSubTitle: true,
    width: "100%",
    height: "564px",
    thumbnailWidth: "50%",
    titleStyle: {
        color: "#000000",
        size: "55px",
        weight: 700,
    },
    subTitleStyle: {
        color: "#000000",
        size: "20px",
        weight: 500,
    },
}

const CategoryPostWrapper = styled.div`
  width: ${({width}) => width && width};
  height: ${({height}) => height && height};
  //min-height: ${({height}) => height && height};
  display: flex;
  flex-direction: row;
  //padding: 0 20px;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }
`;


const CategoryPostContent = styled.div`
  // width: ${({thumbnailWidth, width}) => `calc(${width} * (100% - ${thumbnailWidth}))`};
  width: 100%;
  height: ${({height}) => height && height};
  word-break:break-word;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 10px;
  box-sizing: border-box;
  ${({color}) => color && `background-color: ${color}`};
`;

const Title = styled.div`
  width: 100%;
  color: ${({color}) => color && color};
  font-size: ${({size}) => size && size};
  font-style: normal;
  font-weight: ${({weight}) => weight && weight};
  line-height: normal;
`;

const SubTitle = styled.div`
  width: 100%;
  color: ${({color}) => color && color};
  font-size: ${({size}) => size && size};
  font-style: normal;
  font-weight: ${({weight}) => weight && weight};
  line-height: normal;
`;

const Thumbnail = styled.img`
  width: ${({width}) => width && width};
  min-width: ${({width}) => width && width};
  min-height: ${({height}) => height && height};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  object-fit: cover;
`;
