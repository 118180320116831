import React from 'react';
import Pagination from 'react-js-pagination';
import styled from "styled-components";

export const PAGE_SIZE = 4;
const Paging = ({page, count, setPage}) => {
    return (
        <PagingContainer>
            <Pagination
                activePage={page}
                itemsCountPerPage={PAGE_SIZE}
                totalItemsCount={count}
                pageRangeDisplayed={5}
                hideFirstLastPages={true}
                prevPageText={'Previous'}
                nextPageText={'Next'}
                onChange={setPage}
            />
        </PagingContainer>
    );
};

export default Paging;

const PagingContainer = styled.div`
  .pagination {
    display: flex;
    justify-content: center;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  ul.pagination li {
    display: inline-block;
    width: 60px;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }

  ul.pagination li a {
    text-decoration: none;
    width: fit-content;
    color: #000;
    font-size: 18px;
  }

  ul.pagination li a:hover,
  ul.pagination li.active {
    a {
      color: red;
    }
  }

  .pagination li.disabled {
    a {
      color: #808080;
      &:hover {
        cursor: not-allowed;
      }
    }
  }
`;
