import React, {Suspense, useEffect, useLayoutEffect} from "react";
import {RecoilRoot} from 'recoil';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {FloatingTree} from '@floating-ui/react';
import {RouterProvider, useLocation} from 'react-router-dom';

import './App.css';
import {BrowserView, MobileView, isTablet, isBrowser, isMobile} from 'react-device-detect'

import pcRoutes from "./routes/pcRoutes";
import moRoutes from "./routes/moRoutes";

function App() {
    useEffect(() => {
        const setScreenSize = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        setScreenSize();
        window.addEventListener('resize', setScreenSize);

        return () => window.removeEventListener('resize', setScreenSize);
    }, []);

    return (
        <React.StrictMode>
            <RecoilRoot>
                {/*<Suspense fallback={<SuspenseSpinner/>}>*/}
                <FloatingTree>
                    <DndProvider backend={HTML5Backend}>
                        {(isBrowser || isTablet)
                        &&
                        // <BrowserView>
                        <RouterProvider router={pcRoutes}/>
                            // </BrowserView>}
                        }
                        {(!isTablet && isMobile) &&
                        // <MobileView>
                            <RouterProvider router={moRoutes}/>
                        // </MobileView>
                    }
                    </DndProvider>
                </FloatingTree>
                {/*</Suspense>*/}
            </RecoilRoot>
        </React.StrictMode>
    );
}

export default App;
