import React, {useEffect, useRef} from 'react';
import styled from "styled-components";
import MenuIconSrc from "../../asset/icon__menu.png";
import {useNavigate} from "react-router";
import {useRecoilState} from "recoil";
import {menuState, subMenuState} from "../../recoil/atom";
import MainVideoSrc from "../../asset/offshoot.mp4";
import BloodImgSrc from "../../asset/bow.gif"
import {Spacing} from "../pages/MainPage";
import useRouterScrollTop from "../../hooks/useRouterScrollTop";

export const MenuContent = ({open, onClose}) => {
    useEffect(() => {
        const setScreenSize = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        setScreenSize();
        window.addEventListener('resize', setScreenSize);

        return () => window.removeEventListener('resize', setScreenSize);
    }, []);

    const scrollRef = useRef(null);

    useRouterScrollTop(scrollRef.current);

    const navigate = useNavigate();
    const [, setSelectedMenu] = useRecoilState(menuState);
    const [selectedSubMenu, setSelectedSubMenu] = useRecoilState(subMenuState);

    return <>
        {open &&
        <>
            <Background>
                <video style={{objectFit: 'cover'}} height="100%" autoPlay muted playsInline loop preload="auto">
                    <source src={MainVideoSrc} type="video/mp4"/>
                </video>
            </Background>
            <Filter/>
            <MenuContainer ref={scrollRef}>
                <MenuElement
                    onClick={() => {
                        setSelectedMenu("/magazine");
                        setSelectedSubMenu('all');
                        navigate("/magazine");
                        onClose();
                    }}>Magazine</MenuElement>
                <MenuElement
                    onClick={() => {
                        setSelectedMenu("/shop/2d");
                        setSelectedSubMenu('all');
                        navigate("/shop/2d");
                        onClose();
                    }}>Shop</MenuElement>
                <MenuElement
                    onClick={() => {
                        setSelectedMenu("/pistol");
                        setSelectedSubMenu('all');
                        navigate("/pistol");
                        onClose();
                    }}>Pistol</MenuElement>
                <MenuElement
                    onClick={() => {
                        setSelectedMenu("/information/about");
                        setSelectedSubMenu('all');
                        navigate("/information/about");
                        onClose();
                    }}>Information</MenuElement>
                <Spacing top="110px"/>
                <Button
                    onClick={() => window.open("https://www.youtube.com/@offshootdotcom")}
                    src={BloodImgSrc}/>
            </MenuContainer>
        </>
        }
    </>
}

const Background = styled.div`
  position: fixed;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  background-color: rgba(255, 255, 255, 1);
`;
const Filter = styled.div`
  position: fixed;
  top: 50px;
  width: 100vw;
  //min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  z-index: 3;
  background-color: white;
  mix-blend-mode: difference;
`;
const MenuContainer = styled.div`
  font-family:"Helvetica LT W05 Roman";
  width: 100vw;
  //height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;
const MenuElement = styled.div`
  color: #ffffff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 20px;
`;
const Button = styled.img`
  width: 250px;
  src: url(${({src}) => src && src});

  &:hover {
    cursor: pointer;
  }
`;

const Menu = ({onClick, onClose}) => {
    return (
        <MenuIconWrapper>
            <MenuIcon onClick={onClick} src={MenuIconSrc}/>
        </MenuIconWrapper>
    );
};

export default Menu;

const MenuIconWrapper = styled.div`
  width: 24px;
  padding-right: 20px;
`;

const MenuIcon = styled.img`
  src: ${({src}) => src && src};
  width: 24px;
  padding-right: 20px;
`;
