import React, {useContext, useEffect, useState} from 'react';
import BasketIconSrc from "../../asset/icon__basket.png";
import BasketIconHoverSrc from "../../asset/icon__basket-hover.png";
import BasketIconFullSrc from "../../asset/icon__basket-full.png";
import CloseIconSrc from "../../asset/icon__close.png"
import styled from "styled-components";
import {useRecoilState} from "recoil";
import {basketState} from "../../recoil/atom";
import BasketGoodItem from "./BasketGoodItem";
import {ShopContext} from "../../context/ShopProvider";
import {useNavigate} from "react-router";

export const BasketContent = ({onClose}) => {
    const {checkout} = useContext(ShopContext);
    const navigate = useNavigate();

    useEffect(() => {
        const setScreenSize = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        setScreenSize();
        window.addEventListener('resize', setScreenSize);

        return () => window.removeEventListener('resize', setScreenSize);
    }, []);
    // const [basketItem, setBasketItem] = useRecoilState(basketState);
    return <BasketContentWrapper>
        <HeaderWrapper>
            <HeaderLeft>
                <img src={BasketIconFullSrc} width="25px"/>
                {`${checkout?.lineItems?.length} Item`}
            </HeaderLeft>
            <CloseButton src={CloseIconSrc} width="20px" onClick={onClose}/>
        </HeaderWrapper>
        <ContentWrapper>
            {checkout?.lineItems?.map((item, idx) => <BasketGoodItem key={idx} item={item}/>)}
            {/*{basketItem?.map((item, idx) => <BasketGoodItem key={idx} item={item}/>)}*/}
        </ContentWrapper>
        <FooterWrapper>
            <div style={{fontSize: '13px', height: '35px', display: 'flex', alignItems: 'center'}}>
                Shipping calculated at checkout
            </div>
            <CheckoutButton onClick={() => window.location.replace(checkout.webUrl)}>
                <div>Checkout</div>
                {/*<div>{`$${basketItem?.reduce((prev, current) => prev + Number(current.price * current.count), 0)?.toFixed(2) || 0.00}`}</div>*/}
                {/*<div>{`$${checkout?.totalPrice?.toFixed(2) || 0.00}`}</div>*/}
                <div>{`$${checkout?.totalPrice?.amount || 0.00}`}</div>
            </CheckoutButton>
        </FooterWrapper>
    </BasketContentWrapper>
};

const BasketContentWrapper = styled.div`
  width: 450px;
  //height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const HeaderWrapper = styled.div`
  width: 410px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #000000;
`;

const HeaderLeft = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const CloseButton = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 410px;
  max-height: calc(100vh - 135px);
  height: calc(100vh - 135px);
  overflow-y: auto;
  padding: 0 3px;
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 410px;
  padding: 0 3px;
  border-top: 1px solid #000000;
`;

const CheckoutButton = styled.div`
  width: 410px;
  height: 40px;
  font-size: 15px;
  background-color: #000000;
  color: #ffffff;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  &:hover {
    cursor: pointer;
  }
`;

const Basket = ({itemCount, onClick}) => {
    const [basketHover, setBasketHover] = useState(false);

    return (
        <BasketWrapper onMouseOver={() => setBasketHover(true)}
                       onMouseOut={() => setBasketHover(false)}
                       onClick={() => {
                           onClick();
                       }}
        >
            <img width={30} src={basketHover ? BasketIconHoverSrc : itemCount > 0 ? BasketIconFullSrc : BasketIconSrc}/>
        </BasketWrapper>
    );
};

export default Basket;

const BasketWrapper = styled.div`
  width: 30px;
  height: 30px;

  &:hover {
    cursor: pointer;
  }
`;
