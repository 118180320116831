import {atom, selector} from "recoil";
import {recoilPersist} from 'recoil-persist';
import {pistolApi} from "../api/pistol/PistolApi";

const {persistAtom} = recoilPersist({
    key: 'persists-atom',
    storage: sessionStorage,
});
export const playVideoState = atom({
    key: 'playVideoState',
    default: true,
    effects_UNSTABLE: [persistAtom],
})
export const showModalState = atom({
    key: 'showModalState',
    default: true,
    effects_UNSTABLE: [persistAtom],
})
export const basketState = atom({
    key: 'basketState',
    default: [],
    effects_UNSTABLE: [persistAtom],
})
export const menuState = atom({
    key: 'menuState',
    default: null,
    effects_UNSTABLE: [persistAtom],
})
export const subMenuState = atom({
    key: 'subMenuState',
    default: 'all',
    effects_UNSTABLE: [persistAtom],
})
export const collectionState = atom({
    key: 'collectionState',
    default: '23-fw-taboo',
    effects_UNSTABLE: [persistAtom],
})

const EXTENSIONS_VIDEO = ["mp4", "avi", "mov", "mpg", "wmv", "mpeg"]

const pistolSelector = selector({
    key: 'pistolSelector',
    get: async ({get}) => {
        const {data} = await pistolApi.getPistols();

        return data.map((pistol) => ({
                ...pistol, fileList: pistol.fileList?.filter(file => !file.filePath.endsWith("/"))?.map(file => {
                    const fileName = file.filePath.split("/").pop();
                    return {
                        ...file, fileType: EXTENSIONS_VIDEO.includes(file.filePath.split(".").pop().toLowerCase()) ? "video" : "image",
                    }}
                )
            })
        );
    }
})
export const pistolState = atom({
    key: 'pistolState',
    default: pistolSelector,
    effects_UNSTABLE: [persistAtom],
})
