import React, {useEffect} from 'react';
import styled from "styled-components";
import {useRecoilState} from "recoil";
import {menuState, subMenuState} from "../../../recoil/atom";
import {useNavigate} from "react-router";
import FooterImgSrc from "../../../asset/tree.png"

const Footer = () => {
    const navigate = useNavigate();
    const [selectedMenu, setSelectedMenu] = useRecoilState(menuState);
    const [selectedSubMenu, setSelectedSubMenu] = useRecoilState(subMenuState);
    // const handleMenuClick = (menuKey) => {
    //     setSelectedMenu(menuKey);
    //     navigate(`/${menuKey}`);
    // }

    return (
        <MenuContainer>
            <MenuWrapper>
                <Menu selected={selectedMenu?.includes('about')}
                      onClick={() => {
                          setSelectedSubMenu('all');
                          navigate("/about");
                      }}>
                    About
                </Menu>
                <Menu selected={selectedMenu?.includes('submission')}
                      onClick={() => {
                          setSelectedSubMenu('all');
                          navigate("/submission");
                      }}>
                    Submission
                </Menu>
                <Menu selected={selectedMenu?.includes('stockists')}
                      onClick={() => {
                          setSelectedSubMenu('all');
                          navigate("/stockists");
                      }}>
                    Stockists
                </Menu>
                <Menu selected={selectedMenu?.includes('newsletter')}
                      onClick={() => {
                          setSelectedSubMenu('all');
                          navigate("/newsletter");
                      }}>
                    Newsletter
                </Menu>
                <Menu selected={selectedMenu?.includes('shipping-returns')}
                      onClick={() => {
                          setSelectedSubMenu('all');
                          navigate("/shipping-returns");
                      }}>
                    Shipping & Returns
                </Menu>
                <Menu selected={selectedMenu?.includes('contact-legal')}
                      onClick={() => {
                          setSelectedSubMenu('all');
                          navigate("/contact-legal");
                      }}>
                    Contact & Legal
                </Menu>
            </MenuWrapper>
            <img src={FooterImgSrc} width="50px" height="60px"/>
        </MenuContainer>
    );
};

export default Footer;

const MenuContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  //width: calc(max(100vw, 1630px));
  width: 100vw;
  min-width: calc(max(750px, 100vw));
  height: 185px;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  //min-width: calc(max(750px, 100vw));
  padding: 0 20px;

  background-color: #ffffff;
`;
const MenuWrapper = styled.div`
  min-width: fit-content;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  text-align: center;
  align-items: center;
`;

const Menu = styled.div`
  width: fit-content;
  padding: 10px;
  font-size: 15px;
  line-height: normal;
  text-decoration-line: underline;
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }

  ${({selected}) => selected ? 'color: #C02126' : 'color:black'};
`;
