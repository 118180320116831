import {useLayoutEffect} from "react";
import {useLocation} from "react-router-dom";
import {useNavigationType} from "react-router";

const useRouterScrollTop = (scrollElement) => {
    const location = useLocation();
    const navType = useNavigationType();

    useLayoutEffect(() => {
        if (navType === 'PUSH') {
            scrollElement ? scrollElement.scrollTo(0, 0) : document.documentElement.scrollTo(0, 0);
        }
    }, [location.pathname, navType, scrollElement])
};

export default useRouterScrollTop;
