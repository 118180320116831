import React, {useEffect, useMemo, useState} from 'react';
import {useLocation} from "react-router-dom";
import styled from "styled-components";
import {Spacing} from "../MainPage";
import {useNavigate} from "react-router";
import CategoryPostItem from "../../component/CategoryPostItem";
import {Menu, MenuWrapper, SubMenuContainer} from "./MagazineBasePage";
import {useRecoilState} from "recoil";
import {menuState} from "../../../recoil/atom";
import Paging, {PAGE_SIZE} from "../../component/Paging";
import {NoResult} from "../../component/Search";
import {magazineApi} from "../../../api/magazine/magazineApi";

const MagazineCategoryPage = () => {

    const [selectedMenu, setSelectedMenu] = useRecoilState(menuState);
    const [posts, setPosts] = useState();

    useEffect(() => {
        (async () => {
            const {data} = await magazineApi.getPostByCategory(selectedMenu.includes('fashion') ? 'fashion' : 'art');
            setPosts(data);
        })();
    }, [selectedMenu]);

    const [currentPage, setCurrentPage] = useState(1);

    const postList = useMemo(() =>
            posts?.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)
        , [currentPage, posts]);

    const {pathname} = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setSelectedMenu(pathname);
        setCurrentPage(1);
    }, [pathname, setSelectedMenu]);

    useEffect(() => {
        window.scrollTo({top: document.getElementById("offshoot-contents").offsetTop});
    }, [selectedMenu, currentPage]);

    const handleItemClick = (id) => {
        navigate(`/magazine/${id}`)
    }

    return (
        <Container>
            <SubMenuContainer>
                <MenuWrapper>
                    <Menu size="18px" selected={selectedMenu?.includes('fashion')}
                          onClick={() => navigate("/magazine/fashion")}>
                        Fashion
                    </Menu>
                    <Menu size="18px" selected={selectedMenu?.includes('art')}
                          onClick={() => navigate("/magazine/art")}>
                        Art
                    </Menu>
                </MenuWrapper>
            </SubMenuContainer>
            <Spacing top="40px"/>
            <PageSubTitle>{selectedMenu?.includes('fashion') ? 'Fashion' : 'Art'}</PageSubTitle>
            <Spacing top="20px"/>
            {postList?.length > 0 ? postList?.map((post) =>
                <div key={post.id}
                     style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <CategoryPostItem
                        title={post.title}
                        titleStyle={{size: "25px", weight: 700, color: post.color === '#000000' ? 'white' : 'black'}}
                        subTitle={post.subTitle}
                        subTitleStyle={{size: '18px', color: post.color === '#000000' ? 'white' : 'black'}}
                        thumbnailSrc={post.categoryThumbnail}
                        width="calc(100vw-20px)"
                        color={post.color}
                        onClick={() => handleItemClick(post.id)}
                    />
                    <Spacing top="10px"/>
                </div>
            ) : <EmptyPostListWrapper>
                <NoResult/>
            </EmptyPostListWrapper>}
            <Spacing top="30px"/>
            {postList?.length > 0 && <Paging page={currentPage} setPage={setCurrentPage} count={posts?.length}/>}
            <Spacing top="30px"/>
            <Spacing top="50px"/>
        </Container>
    )
};

export default MagazineCategoryPage;

export const Container = styled.div`
  font-family: "Helvetica LT W05 Roman";
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 0 20px;
`;

export const PageSubTitle = styled.div`
  font-size: 40px;
  font-weight: 900;
`;

const EmptyPostListWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
