import {getRequest} from "../../axios";

const baseUrl = `/api/v1/pistol`;

const getPistols = () =>
    getRequest({
        url: `${baseUrl}`,
    });

const getPistol = (id) =>
    getRequest({
        url: `${baseUrl}/${id}`,
    });

export const pistolApi = {
    getPistols,
    getPistol,
}
