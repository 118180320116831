import React, {useEffect, useState} from 'react';
import Client from "shopify-buy";

const ShopContext = React.createContext();

const client = Client.buildClient(
    {
        domain: process.env.REACT_APP_DOMAIN,
        storefrontAccessToken: process.env.REACT_APP_STORE_FRONT_ACCESS_TOKEN,
    }
);

const ShopProvider = ({children}) => {
    const [product, setProduct] = useState({});
    const [products, setProducts] = useState([]);
    const [collections, setCollections] = useState({});
    const [collection, setCollection] = useState({});
    const [checkout, setCheckout] = useState({});
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false); // for mobile

    useEffect(() => {
        if (localStorage.checkout_id) {
            (async () => await fetchCheckout(localStorage.checkout_id))();
        } else {
            (async () => await createCheckout())();
        }
    }, []);

    const createCheckout = async () => {
        const checkout = await client.checkout.create();
        localStorage.setItem("checkout_id", checkout.id);
        setCheckout(checkout);
    };

    const fetchCheckout = async (checkoutId) => {
        const checkout = await client.checkout.fetch(checkoutId);
        setCheckout(checkout);
    };

    const addItemToCheckout = async (variantId, quantity) => {
        const lineItemsToAdd = [
            {
                variantId,
                quantity: parseInt(quantity, 10)
            },
        ];
        const newCheckout = await client.checkout.addLineItems(checkout.id, lineItemsToAdd);
        setCheckout(newCheckout);
        openDrawer();
    };

    const removeLineItem = async (lineItemIdsToRemove) => {
        const newCheckout = await client.checkout.removeLineItems(checkout.id, lineItemIdsToRemove);
        setCheckout(newCheckout);
    };

    const fetchProductWithHandle = async (handle) => {
        const product = await client.product.fetchByHandle(handle);
        setProduct(product);
    };

    const fetchAllProducts = async () => {
        const products = await client.product.fetchAll();
        setProducts(products?.filter(({productType}) => productType !== ''));
    };

    const fetchCollectionWithHandle = async (handle) => {
        const collection = await client.collection.fetchByHandle(handle);
        setCollection(collection);
    };

    const fetchCollectionByHandle = async (handle) => {
        const collection = await client.collection.fetchByHandle(handle);
        return collection;
    };

    const fetchAllCollections = async () => {
        const products = await client.product.fetchAll();
        let collectionList = [];
        products.forEach((product) => {
            const collectionHandle = product.productType.split(",");
            if (collectionHandle.length > 1 && collectionHandle[1] !== 'object') {
                collectionList = [...collectionList, collectionHandle[1].toLowerCase()];
            }
        });

        collectionList.forEach(async (handle) => {
            const collection = await fetchCollectionByHandle(handle);
            setCollections((prev) => ({...prev, [handle]: collection}));
        });
    };

    const closeDrawer = () => {
        setDrawerOpen(false);
    };
    const openDrawer = () => {
        setDrawerOpen(true);
    };
    const closeMenu = () => {
    };
    const openMenu = () => {
    };
    return (
        <ShopContext.Provider
            value={{
                product,
                products,
                collection,
                collections,
                checkout,
                drawerOpen,
                isMenuOpen,
                fetchAllProducts,
                fetchProductWithHandle,
                fetchCollectionWithHandle,
                fetchAllCollections,
                addItemToCheckout,
                removeLineItem,
                closeDrawer,
                openDrawer,
                closeMenu,
                openMenu,
            }}>
            {children}
        </ShopContext.Provider>
    );
};

const ShopConsumer = ShopContext.Consumer;

export {ShopContext, ShopConsumer}
export default ShopProvider;
