import React from 'react';
import {Spacing} from "../../../pc/pages/MainPage";
import styled from "styled-components";

const ClientServerErrorPage = () => {
    return (
        <Container>
            <Content>
                Server Error.
            </Content>
            <Spacing top="30px"/>
        </Container>
    );
};

export default ClientServerErrorPage;

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  max-height: calc(calc(var(--vh, 1vh) * 100) - 50px);
  padding: 0 10px;
  min-width: calc(min(350px, 80vw));
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  overflow: hidden;
  position: fixed;
`;
const Content = styled.div`
  color: #000;
  font-family: "Helvetica LT W05 Roman";
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
