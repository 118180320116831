import axios from "axios";

const axiosClient = axios.create({
    baseURL: "https://offshootdot.com",
    withCredentials: true,
});

export const postRequest = (options) => {
    return axiosClient({
        ...options,
        method: 'POST'
    })
}

export const getRequest = (options) => {
    return axiosClient({
        ...options,
        method: 'GET'
    })
}
