import React, {useContext, useState} from 'react';
import styled from "styled-components";
import PlusIconSrc from "../../asset/icon__plus.png";
import MinusIconSrc from "../../asset/icon__minus.png"
import DeleteIconSrc from "../../asset/icon__bin.png"
import DeleteHoverIconSrc from "../../asset/icon__bin-hover.png"
import {useRecoilState} from "recoil";
import {basketState} from "../../recoil/atom";
import {ShopContext} from "../../context/ShopProvider";

const QuantityCounter = ({handleClickPlus, handleClickMinus, handleDelete, count}) => {
    const [deleteHover, setDeleteHover] = useState(false);

    return (
        <CounterContainer>
            <div style={{fontSize: '13px'}}>Quantity</div>
            <CounterSubContainer>
                <PlusMinusContainer>
                    <Button src={MinusIconSrc} onClick={handleClickMinus}/>
                    <div>{count}</div>
                    <Button src={PlusIconSrc} onClick={handleClickPlus}/>
                </PlusMinusContainer>
                <Button
                    style={{width: "30px"}}
                    onClick={handleDelete}
                    onMouseOver={() => setDeleteHover(true)}
                    onMouseOut={() => setDeleteHover(false)}
                    src={deleteHover ? DeleteHoverIconSrc : DeleteIconSrc}/>
            </CounterSubContainer>
        </CounterContainer>
    );
}

const CounterContainer = styled.div`
  width: 144px;
  height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const CounterSubContainer = styled.div`
  width: 190px;
  height: 47px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const PlusMinusContainer = styled.div`
  width: 144px;
  height: 47px;
  background-color: #EFEFEF;
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.img`
  width: 24px;
  height: 24px;
  src: url(${({src}) => src && src});

  &:hover {
    cursor: pointer;
  }
`;

const BasketGoodItem = ({item}) => {
    const {removeLineItem, addItemToCheckout, checkout} = useContext(ShopContext);
    const handleClickPlus = () => {
        addItemToCheckout(item.variant.id, 1);
    }
    const handleClickMinus = (id) => {
        if (item.quantity <= 1) removeLineItem(id);
        else addItemToCheckout(item.variant.id, -1);
    }
    const handleDelete = (id) => {
        removeLineItem(id);
    }
    return (
        <Container>
            <GoodDetailContainer>
                <GoodDetail>
                    <HeaderWrapper>
                        <Name>{item.title}</Name>
                        {/*<SubName>{item.subName}</SubName>*/}
                    </HeaderWrapper>
                    <Price>{`${item.variant.price.amount || 0}$`}</Price>
                </GoodDetail>
                <QuantityCounter id={item.id} count={item.quantity}
                                 handleClickPlus={() => handleClickPlus()}
                                 handleClickMinus={() => handleClickMinus(item.id)}
                                 handleDelete={() => handleDelete(item.id)}/>
                {/*<QuantityCounter id={item.id} count={item.count} onClickPlus={() => handleClickPlus(item.id)} onClickMinus={() => handleClickMinus(item.id)}/>*/}
            </GoodDetailContainer>
            <ImageContainer>
                <Image src={item.variant.image.src}/>
            </ImageContainer>
        </Container>
    );
};

export default BasketGoodItem;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 187px;
  width: 100%;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  max-height: 140px;
  src: ${({src}) => src && src};
`;

const GoodDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
`;
const GoodDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const Name = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const SubName = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const Price = styled.div`
  color: #000;
  font-size: 16px;
  font-weight: 300;
`;
