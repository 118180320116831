import React, {useMemo} from 'react';
import styled from "styled-components";
import {Spacing} from "../pages/MainPage";


const PostItem = ({img, title, content, width, height, onClick, bySearch, searchKey}) => {
    const handleClickPost = () => {
        onClick && onClick(content.id, content.category);
    }

    const coloredTitle = useMemo(() => {
        if (bySearch && searchKey !== '') {
            let splitTitle = title.split(' ');
            splitTitle = splitTitle.map((key) => {
                if (key.toLowerCase().includes(searchKey.toLowerCase())) {
                    return `<span style="background-color: red; color: black;">${key}</span>`;
                }
                return `<span style="color: #000;">${key}</span>`;
            })
            return splitTitle.join(' ');
        }
    }, [bySearch, searchKey, title]);

    return (
        <Container width={width} height={height}>
            <Thumbnail src={img} width={width} height={width} onClick={handleClickPost}/>
            <Spacing top="10px"/>
            {bySearch && searchKey !== '' ? <Title onClick={handleClickPost} dangerouslySetInnerHTML={{__html: coloredTitle}}/> :
                <Title onClick={handleClickPost}>{title}</Title>}
        </Container>
    );
};

export default PostItem;

PostItem.defaultProps = {
    width: "173px",
    height: "350px",
}

const Container = styled.div`
  width: ${({width}) => width && width};
  height: ${({height}) => height && height};
  display: flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;
  }
`;

const Thumbnail = styled.img`
  object-fit: cover;
  width: ${({width}) => width && width};
  height: ${({height}) => height && height};
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;

  &:hover {
    color: #ff0000;
  }
`;
