import React, {lazy} from 'react';
import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import RootRoute from "./RootRoute";
import ClientServerErrorPage from "../mo/pages/error/ClientServerErrorPage";
import HomePage from "../mo/pages/HomePage";
import MainPage from "../mo/pages/MainPage";
import MagazineCategoryPage from "../mo/pages/magazine/MagazineCategoryPage";
import PageNotFoundPage from "../mo/pages/error/PageNotFoundPage";

const MagazinePage = lazy(() => import('../mo/pages/magazine/MagazineBasePage'));
const MagazineDetailPage = lazy(() => import('../mo/pages/magazine/MagazineDetailPage'));
// const MagazineCategoryPage = lazy(() => import('../mo/pages/magazine/MagazineCategoryPage'));
const ShopPage = lazy(() => import('../mo/pages/shop/ShopPage'));
const Shop3DPage = lazy(() => import('../mo/pages/shop/Shop3DPage'));
const ShopDetailPage = lazy(() => import('../mo/pages/shop/ShopDetailPage'));
const LookbookPage = lazy(() => import('../mo/pages/shop/LookbookPage'));
const PistolPage = lazy(() => import('../mo/pages/pistol/PistolPage'));
const PistolDetailPage = lazy(() => import('../mo/pages/pistol/PistolDetailPage'));
const AboutPage = lazy(() => import('../mo/pages/footer/AboutPage'));
const StockistsPage = lazy(() => import('../mo/pages/footer/StockistsPage'));
const NewsletterPage = lazy(() => import('../mo/pages/footer/NewsletterPage'));
const ShippingReturnsPage = lazy(() => import('../mo/pages/footer/ShippingReturnsPage'));
const ContactLegalPage = lazy(() => import('../mo/pages/footer/ContactLegalPage'));

const moRoutes = createBrowserRouter(
    createRoutesFromElements(
        <Route path="" element={<RootRoute/>}>
            <Route path="" element={<HomePage/>} errorElement={<ClientServerErrorPage/>}>
                <Route path="" element={<MainPage/>}/>
                <Route path="magazine" element={<MagazinePage/>}/>
                <Route path="magazine/fashion" element={<MagazineCategoryPage/>}/>
                <Route path="magazine/art" element={<MagazineCategoryPage/>}/>
                <Route path="magazine/:postNo" element={<MagazineDetailPage/>}/>
                <Route path="shop" element={<ShopPage/>}/>
                <Route path="shop/2d" element={<ShopPage/>}/>
                <Route path="shop/3d" element={<Shop3DPage/>}/>
                <Route path="shop/3d/collection" element={<LookbookPage/>}/>
                <Route path="shop/3d/collection/:handle" element={<LookbookPage/>}/>
                <Route path="shop/4d" element={<ShopPage/>}/>
                <Route path="shop/xd" element={<ShopPage/>}/>
                <Route path="shop/:handle" element={<ShopDetailPage/>}/>
                <Route path="pistol" element={<PistolPage/>}/>
                <Route path="pistol/:pistolNo" element={<PistolDetailPage/>}/>
                <Route path="information/about" element={<AboutPage/>}/>
                <Route path="information/stockists" element={<StockistsPage/>}/>
                <Route path="information/newsletter" element={<NewsletterPage/>}/>
                <Route path="information/shipping-returns" element={<ShippingReturnsPage/>}/>
                <Route path="information/contact-legal" element={<ContactLegalPage/>}/>
                <Route path="*" element={<PageNotFoundPage />}/>
            </Route>
        </Route>
    )
);

export default moRoutes;
