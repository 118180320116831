import React, {useEffect, useState, useRef, useCallback} from 'react';
import styled from 'styled-components';
import {Outlet, useLocation} from "react-router-dom";
import Contents from "./layout/Contents";
import Gnb, {GNB_TYPE} from "./layout/Gnb";
import {useNavigate} from "react-router";
import {Spacing} from "./MainPage";
import MainVideoSrc from "../../asset/offshootdot.mp4";
import {useRecoilState} from "recoil";
import {playVideoState} from "../../recoil/atom";

const HomePage = () => {
    useEffect(() => {
        const setScreenSize = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        setScreenSize();
        window.addEventListener('resize', setScreenSize);

        return () => window.removeEventListener('resize', setScreenSize);
    }, []);
    const [playVideo, setPlayVideo] = useRecoilState(playVideoState);
    const [gnbType, setGnbType] = useState(GNB_TYPE.DEFAULT);
    const [, setSelectedMenu] = useState(null);

    const {pathname} = useLocation();

    useEffect(() => {
        if (pathname.includes('magazine')) {
            const temp = pathname.split("/");
            if (temp.length === 3 && !pathname.includes('fashion') && !pathname.includes('art')) {
                setGnbType(GNB_TYPE.GAUGE);
            } else {
                setGnbType(GNB_TYPE.DEFAULT);
            }
        } else {
            setGnbType(GNB_TYPE.DEFAULT);
        }
        setSelectedMenu(pathname);
    }, [pathname])

    useEffect(() => {
        const handleVideoEnd = (e) => {
            const element = document.querySelector('.video_obj');
            element.animate(
                [
                    {transform: "rotate(0) scale(1)"},
                    {transform: "rotate(360deg) scale(0)"},
                ],
                {
                    durations: 1800,  // 밀리초 지정
                    iterations: 1,
                    // fill: 'forwards',  // 종료 시 속성을 지님
                    // easing: 'ease'  // 가속도 종류
                }
            );
            setTimeout(() => setPlayVideo(false), 2000);
        }
        document.getElementById('video_obj')?.addEventListener('ended', handleVideoEnd, false);
    }, [setPlayVideo, playVideo]);

    return (
        <Container>
            {pathname.split("/").length === 2 && playVideo &&
            <Background onClick={() => setPlayVideo(false)}>
                {/*<video style={{objectFit: 'cover', height: '100vh'}} className="video_obj" id={'video_obj'} width="100%" autoPlay muted playsInline preload="auto">*/}
                <video style={{height: 'fit-content', width: '100vw'}} className="video_obj" id={'video_obj'} width="100%" autoPlay muted playsInline preload="auto">
                    <source src={MainVideoSrc} type="video/mp4"/>
                </video>
            </Background>}
            <Body>
                <Gnb type={gnbType}/>
                <Spacing top="50px"/>
                <Contents>
                    <Outlet/>
                </Contents>
            </Body>
        </Container>
    );
};

export default HomePage;
const Background = styled.div`
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: black;
  object-fit: none;
`;

const Container = styled.div`
  //max-height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  width: 100vw;
  align-items: flex-start;
  overflow: hidden;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
  font-family: 'Helvetica LT W05 Bold';

  & > #contents-utility {
    align-self: stretch;
    marin-top: 50px;
  }
`
