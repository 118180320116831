import {postRequest} from "../../axios";

const baseUrl = `/api/v1/news-letter`;

const subscribe = (subscriber) =>
    postRequest({
        url: `${baseUrl}`,
        data: subscriber
    });

export const newsLetterApi = {
    subscribe,
}



