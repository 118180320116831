import React, {useRef, Suspense, useState, useEffect} from 'react';
import useRouterScrollTop from "../../../hooks/useRouterScrollTop";
import styled from "styled-components";

const Contents = (props) => {
    const {children} = props;
    const scrollRef = useRef(null);

    useRouterScrollTop(scrollRef.current);

    return (
        <ScrollBoundary id="offshoot-contents" ref={scrollRef}>
            <Suspense>{children}</Suspense>
        </ScrollBoundary>
    );
};

export default Contents;

const ScrollBoundary = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 50px);
  align-items: flex-start;
  overflow: auto;
  overflow-x: hidden;
  //@media only screen and (min-width: 1440px) {
  //  overflow-x: hidden;
  //}
`
