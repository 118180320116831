import React from 'react';
import {Spacing} from "../MainPage";
import Footer from "../layout/Footer";
import styled from "styled-components";

const PageNotFoundPage = () => {
    return (
        <>
            <Container>
                <Spacing top="50px"/>
                <Spacing top="30px"/>
                <Content>
                    Page Not Found.
                    <br/>Please check the URL.
                </Content>
                <Spacing top="80px"/>
            </Container>
            <Footer/>
        </>
    );
};

export default PageNotFoundPage;

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 0 20px;
`;

const Content = styled.div`
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
