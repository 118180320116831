import React, {useContext, useEffect} from 'react';
import {useNavigate} from "react-router";
import {Spacing} from "../../pc/pages/MainPage";
import styled from "styled-components";
import GoodItem from "./GoodItem";
import {ShopContext} from "../../context/ShopProvider";
import {useRecoilState} from "recoil";
import {pistolState} from "../../recoil/atom";

const RelatedGoodList = ({onClick, relatedGoodIdList, bySearch = false, searchKey = ''}) => {
    const navigate = useNavigate();
    const {fetchAllProducts, products} = useContext(ShopContext);
    const [pistols, setPistols] = useRecoilState(pistolState);

    useEffect(() => {
        fetchAllProducts();
    }, []);

    const relatedGoods = products.filter(({handle}) => relatedGoodIdList.includes(handle));

    return (
        <Container>
            {!bySearch && <Title>Related Products</Title>}
            {!bySearch && <Spacing top="15px"/>}
            <GoodListWrapper>
                {relatedGoods?.map((product) => <GoodItem
                    bySearch
                    searchKey={searchKey}
                    key={product.id}
                    width={`${bySearch ? 'calc((300px - 40px) / 2)' : 'calc((100vw - 50px) / 2)'}`}
                    height={`${bySearch ? 'calc((300px - 40px) / 2 * 1.4)' : 'calc((100vw - 50px) / 2 * 1.4)'}`}
                    good={product}
                    img={product.images[0].src}
                    name={product.title}
                    subName={''}
                    price={product.variants[0].price.amount}
                    onClick={() => {
                        onClick && onClick();
                        if (product?.productType.split(",")?.length > 2 && product?.productType.split(",")?.[2] === 'pistol') {
                            navigate(`/pistol/${pistols?.filter(({key}) =>
                                key.toLowerCase() === product?.productType.split(",")?.[1]?.toLowerCase()
                            )[0].id || ''}`)
                        } else {
                            window.open(`https://shop.offshootdot.com/products/${product?.handle}`)
                        }
                    }}
                />)}
            </GoodListWrapper>
        </Container>
    );
};

export default RelatedGoodList;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: "Helvetica LT W05 Roman";
`;

const Title = styled.div`
  font-size: 18px;
`;

const GoodListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px 20px;
  align-items: center;
  justify-content: space-between;
`;
